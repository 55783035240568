import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

function ContactPage() {
  return (
    <Layout>
      <SEO
        title="信息發送成功"
      />
      <section>
          <p className="text-center text-2xl align-middle mb-8 leading-loose">
            你的信息已經成功發送。
          </p>
      </section>
    </Layout>
  );
}

export default ContactPage;
